import "./App.css";
import React, { Suspense, lazy } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import BackToTop from "./BackToTop";
import { Route, Routes } from "react-router-dom";
import { CabBookingProvider } from "./Cab/CabBooking.context";
import { SDBookingProvider } from "./Selfdrive/Choosevehicle/SDBooking.Context.js";

// Lazily loaded components
const Main = lazy(() => import("./Main/Main"));
const Login = lazy(() => import("./Login/Login"));
const Signup = lazy(() => import("./Login/Signup"));
const Profile = lazy(() => import("./Login/Profile"));
const Profiledetails = lazy(() => import("./Login/Profiledetails"));
const Vehiclecategory = lazy(() => import("./Selfdrive/Vehiclecategory/Vehiclecategory"));
const Document = lazy(() => import("./Selfdrive/Document/Document"));
const Choosevehicle = lazy(() => import("./Selfdrive/Choosevehicle/Choosevehicle"));
const Billpay = lazy(() => import("./Selfdrive/Billpay/Billpay"));
const Thankyou = lazy(() => import("./Selfdrive/Thankyou/Thankyou"));
const Newpassword = lazy(() => import("./Login/Newpassword"));
const Taximain = lazy(() => import("./Taxi/Taximain/Taximain"));
const Choosetaxi = lazy(() => import("./Taxi/Choosetaxi/Choosetaxi"));
const Bookingconfirmation = lazy(() => import("./Taxi/Bookingconfirmation/Bookingconfirmation"));
const Logout = lazy(() => import("./Login/Logout"));
const Otptaxi = lazy(() => import("./Taxi/Otptaxi/Otptaxi"));
const Thankyoutaxi = lazy(() => import("./Taxi/Thankyoutaxi/Thankyoutaxi"));
const Outstation = lazy(() => import("./Taxi/Choosetaxi/Outstation"));
const Rentals = lazy(() => import("./Taxi/Choosetaxi/Rentals"));
const Corporate = lazy(() => import("./Corporate/Corporate"));
const Corpmain = lazy(() => import("./Corporate/Corpmain"));
const Corplocal = lazy(() => import("./Corporate/Corplocal"));
const Corptransportation = lazy(() => import("./Corporate/Corptransportation"));
const Corptaxi = lazy(() => import("./Corporate/Corptaxi"));
const Events = lazy(() => import("./Events/Event"));
const Eventmain = lazy(() => import("./Events/Eventmain"));
const EventCategory = lazy(() => import("./Events/EventCategory"));
const Cab = lazy(() => import("./Cab/Cab"));
const Cabmain = lazy(() => import("./Cab/cabmain"));
const Seat = lazy(() => import("./Cab/seat"));
const Verifyuser = lazy(() => import("./Verifyuser"));
const Sdadmin = lazy(() => import("./admin/Sdadmin/Sdadmin"));
const Forgot = lazy(() => import("./Login/Forgot"));
const Testino = lazy(() => import("./Main/Testino"));
const Thankyousd = lazy(() => import("./Selfdrive/Thankyou/Thankyousd"));
const CancelBooking = lazy(() => import("./Selfdrive/Thankyou/Cancel.js"));
const AboutPage = lazy(() => import("./Other/Aboutus"));
const Listyourvehicle = lazy(() => import("./Other/Listyourvehicle"));
const ContactUs = lazy(() => import("./Other/Contactus"));
const DriveWithAssar = lazy(() => import("./Other/Drivewithassar"));
const FranchiseWithAssar = lazy(() => import("./Other/Franchise"));
const Offers = lazy(() => import("./Other/Offers"));
const CSR = lazy(() => import("./Other/CSR"));
const BlogPosts = lazy(() => import("./Other/Blogs"));
const Notice = lazy(() => import("./Other/Notice"));
const TermsAndConditions = lazy(() => import("./Other/Terms"));
const PrivacyPolicy = lazy(() => import("./Other/Privacy"));
const CareersLandingPage = lazy(() => import("./Other/CareersLandingPage"));
const CarSubscription = lazy(() => import("./Other/Carsubscrition"));

function App() {
  return (
    <div>
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route
          path="/Subscription"
          element={
            <SDBookingProvider>
              <CarSubscription />
            </SDBookingProvider>
          }
        />
        <Route path="/Career" element={<CareersLandingPage />} />
        <Route path="/notices" element={<Notice />} />
        <Route path="/Blogs" element={<BlogPosts />} />
        <Route path="/csr-policy" element={<CSR />} />
        <Route path="/Offers" element={<Offers />} />
        <Route path="/become-a-franchise" element={<FranchiseWithAssar />} />
        <Route path="/Selfdrive" element={<Vehiclecategory />} />
        <Route path="/Drivewithassar" element={<DriveWithAssar />} />
        <Route
          path="/Selfdrive/:cat"
          element={
            <SDBookingProvider>
              <Choosevehicle />
            </SDBookingProvider>
          }
        />
        <Route
          path="/billpay/:_id"
          element={
            <SDBookingProvider>
              <Billpay />
            </SDBookingProvider>
          }
        />
        <Route
          path="/thankyou"
          element={
            <SDBookingProvider>
              <Thankyousd />
            </SDBookingProvider>
          }
        />
        <Route path="/thankyoutaxi" element={<Thankyoutaxi />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/cancel/:Booking" element={<CancelBooking />} />
        <Route
          path="/seat/:_id/:formattedDate"
          element={
            <CabBookingProvider>
              <Seat />
            </CabBookingProvider>
          }
        />
        <Route
          path="/thanks"
          element={
            <CabBookingProvider>
              <Thankyou />
            </CabBookingProvider>
          }
        />
        <Route path="/:userRole" element={<Profile />} />
        <Route path="/admin" element={<Main />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/Document" element={<Document />} />
        <Route path="/userdetail" element={<Profiledetails />} />
        <Route path="/changepassword" element={<Newpassword />} />
        <Route path="/taxi" element={<Taximain />} />
        <Route path="/choosetaxi" element={<Choosetaxi />} />
        <Route path="/Bookingconfirmation" element={<Bookingconfirmation />} />
        <Route path="/outstation" element={<Outstation />} />
        <Route path="/rentals" element={<Rentals />} />
        <Route path="/otptaxi" element={<Otptaxi />} />
        <Route path="/List-your-vehicle" element={<Listyourvehicle />} />
        <Route path="/adminhome" element={<Sdadmin />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/corpoutstation" element={<Corpmain />} />
        <Route path="/corplocal" element={<Corplocal />} />
        <Route path="/corptransportation" element={<Corptransportation />} />
        <Route path="/corptaxi" element={<Corptaxi />} />
        <Route path="/event" element={<Events />} />
        <Route path="/eventmain" element={<Eventmain />} />
        <Route path="/cab" element={<Cab />} />
        <Route path="/cabmain" element={<Cabmain />} />
        <Route path="/testino" element={<Testino />} />
        <Route path="/verifyuser/:email" element={<Verifyuser />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/Terms-conditions" element={<TermsAndConditions />} />
        <Route path="/Privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      </Suspense>
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
