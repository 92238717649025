/**
 * Context provider for managing cab booking related state.
 * @param {Object} children - The child components that will have access to the context.
 * @returns {JSX.Element} A provider component that wraps its children with the CabBookingContext.
 */
import React, { createContext, useState } from 'react';

export const CabBookingContext = createContext();
export const CabBookingProvider = ({ children }) => {
  const [paymentData, SetPaymentData] = useState(null);

  return (
    <CabBookingContext.Provider value={{ paymentData, SetPaymentData }}>
      {children}
    </CabBookingContext.Provider>
  );
};
