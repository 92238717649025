import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  const isAdmin = ["admin", "superadmin", "document"].includes(localStorage.getItem("role"));

  return (
    <footer id="footer">
      {!isAdmin && <UserFooter />}
      <div className="pt-3 text-center">
        <div className="copyright">
          Copyright © 2025 Assar Vehicle Rental Services Pvt. Ltd. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

const UserFooter = () => (
  <div className="footer-top">
    <div className="container">
      <div className="row bor_bottom_w">
        <FooterLinks />
        <ContactInfo />
        <TelephoneInfo />
      </div>
      <FooterLogo />
    </div>
  </div>
);

const FooterLinks = () => (
  <div className="col-lg-4 footer-links policy_sec">
    <ul>
      {["CSR Policy", "Blogs", "Notices", "Become a franchise", "Business Solutions", "Transport Solutions"].map((link, index) => (
        <li key={index}>
          <Link to={`/${link.replace(/\s+/g, '-').toLowerCase()}`}>{link}</Link>
        </li>
      ))}
    </ul>
  </div>
);

const ContactInfo = () => (
  <div className="col-lg-4 footer-links">
    <h4 className="footer-heading" style={{ textDecoration: "underline" }}>Keep in Touch</h4>
    <ul>
      {["Info@assarvehicle.com", "Support@assarvehicle.com"].map((email, index) => (
        <li key={index}>
          <Link to={`mailto:${email}`}>{email}</Link>
        </li>
      ))}
    </ul>
    <div className="static_link">
      <Link to="/Terms-conditions">Terms and Conditions</Link>
      <Link to="/Privacy-policy">Privacy Policy</Link>
    </div>
  </div>
);

const TelephoneInfo = () => (
  <div className="col-lg-4 footer-links justify-content-center">
    <h4 style={{ textDecoration: "underline" }}>Our Telephones</h4>
    <ul>
      {[
        { number: "+91-9109999666", imgSrc: "https://assarbucket.s3.ap-south-1.amazonaws.com/img/phone-white.svg" },
        { number: "0731-3564526", imgSrc: "https://assarbucket.s3.ap-south-1.amazonaws.com/img/telephone-white.svg" }
      ].map(({ number, imgSrc }, index) => (
        <li key={index}>
          <Link to={`tel:${number}`}>
            <img src={imgSrc} alt="" className="img-fluid pr-2" />
            {number}
          </Link>
        </li>
      ))}
    </ul>
    <div className="store_icon">
      {["apple-store.png", "google-store.png"].map((src, index) => (
        <i key={index}>
          <img src={`https://assarbucket.s3.ap-south-1.amazonaws.com/img/${src}`} alt="" className="img-fluid" />
        </i>
      ))}
    </div>
  </div>
);

const FooterLogo = () => (
  <div className="row">
    <div className="col-lg-6">
      <Link to="/">
        <img src="https://assarbucket.s3.ap-south-1.amazonaws.com/img/footer-logo.svg" alt="Footer Logo" className="img-fluid" />
      </Link>
    </div>
    <div className="col-lg-6 d-flex align-items-center justify-content-end">
      <SocialLinks />
    </div>
  </div>
);

const SocialLinks = () => {
  const socialMedia = [
    { platform: "whatsapp", url: "https://wa.me/+919109999666" },
    { platform: "linkedin", url: "https://www.linkedin.com/company/assar-vehicle-rental-services-pvt-ltd" },
    { platform: "instagram", url: "https://www.instagram.com/assarvehicle" },
    { platform: "twitter", url: "https://twitter.com/assarvehicle" },
    { platform: "facebook", url: "https://www.facebook.com/Assarvehicle/" }
  ];

  return (
    <div className="social_link">
      {socialMedia.map(({ platform, url }, index) => (
        <a key={index} href={url}>
          <i className={`bx bxl-${platform}`} />
        </a>
      ))}
    </div>
  );
};

export default Footer;
