/**
 * Context provider for SDBookingContext in React.
 * @param {Object} children - The child components that will have access to the context.
 * @returns {JSX.Element} A provider component that wraps the children components with access to BookedData, SetBookedData, PayData, and SetPayData.
 */
import React, { createContext, useState } from "react";
export const SDBookingContext = createContext();
export const SDBookingProvider = ({ children }) => {
  const [BookedData, SetBookedData] = useState("");
  const [PayData, SetPayData] = useState("");

  return (
    <SDBookingContext.Provider
      value={{ BookedData, SetBookedData, PayData, SetPayData }}
    >
      {children}
    </SDBookingContext.Provider>
  );
};
