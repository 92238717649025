import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  const [headerContent, setHeaderContent] = useState(null);
  const role = localStorage.getItem("role");

  // Define roles outside of the useEffect for accessibility
  const adminRoles = ["admin", "superadmin", "document"];
  const userRoles = ["user", "driver", "copartner", "cabdriver"];

  useEffect(() => {
    let content;

    if (adminRoles.includes(role)) {
      content = <AdminHeader />;
    } else if (userRoles.includes(role)) {
      content = <UserHeader />;
    } else {
      content = <DefaultHeader />;
    }

    setHeaderContent(content);
  }, [role]); // You may want to trigger this if the role changes.

  const TopBar = () => (
    <div id="topbar" className="d-none d-lg-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-end">
        <div className="social-links align-items-left">
          {adminRoles.includes(role) ? (
            <>
              <a className="instagram">Welcome Admin Panel</a>
              <a className="skype" >{localStorage.getItem("email")}</a>
              <a className="contact-info" >{localStorage.getItem("name")}</a>
            </>
          ) : (
            <>
              <Link to="/List-your-vehicle">List your vehicle</Link>
              <Link to="/Offers">Offers</Link>
              <Link to="/Subscription">Car Subscription</Link>
              <Link to="/Career">Career</Link>
              <a className="contact-info icofont-phone" href="tel:+919109999666">+91-910-9999-666</a>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const Navbar = () => (
    <nav id="header" className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="https://assarbucket.s3.ap-south-1.amazonaws.com/img/logo-white.svg" alt="" />
        </Link>
        <button
          style={{ color: "white" }}
          className="navbar-toggler custom-icon"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span style={{ color: "white" }} className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {adminRoles.includes(role) && (
              <>
                <NavItem to="/" text="Admin Panel" />
                <NavItem to="/user" icon="bx bx-user-circle" />
              </>
            )}
            {userRoles.includes(role) && (
              <>
                <NavItem to="/" text="Home" />
                <NavItem to="/aboutus" text="About" />
                <NavItem to="/contact" text="Contact" />
                <NavItem to="/Drivewithassar" text="Drive with Assar" />
                <NavItem to="/become-a-franchise" text="Franchise" />
                <NavItem to="/user" icon="bx bx-user-circle" />
              </>
            )}
            {!role && (
              <>
                <NavItem to="/" text="Home" />
                <NavItem to="/aboutus" text="About" />
                <NavItem to="/contact" text="Contact" />
                <NavItem to="/Drivewithassar" text="Drive with Assar" />
                <NavItem to="/become-a-franchise" text="Franchise" />
                <NavItem to="/login" icon="bx bx-user-circle" />
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );

  const NavItem = ({ to, text, icon }) => (
    <li className="nav-item">
      <Link className="nav-link" style={{ color: "#fff" }} to={to} data-toggle="modal" data-target="#exampleModal">
        {icon ? <i style={{ color: "#fff", fontSize: "28px" }} className={icon}></i> : text}
      </Link>
    </li>
  );

  const AdminHeader = () => (
    <div>
      <TopBar />
      <Navbar />
    </div>
  );

  const UserHeader = () => (
    <div>
      <TopBar />
      <Navbar />
    </div>
  );

  const DefaultHeader = () => (
    <div>
      <TopBar />
      <Navbar />
    </div>
  );

  return <div>{headerContent}</div>;
}

export default Header;
